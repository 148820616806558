import React from "react";
import { colors } from "../utilities/colors";
import { Link } from "gatsby";
import { Flex, Box } from "./FlexBox";
import { maxWidth } from "./Layout";

import Navigation, { MobileNavigation } from "./Navigation";
import Logo from "../images/igk_logo_web.svg";

const LinkStyles = {
    textDecoration: "none",
    display: "flex",
    flex: "1 1 auto",
};

const Header = () => {
    return (
        <>
            <Flex
                width="100%"
                flex="0 0 auto"
                flexDirection="column"
                display={["block", null, "flex"]}
                alignItems="center"
                zIndex="3000"
                bg="white"
                pt={[0, null, "33px"]}
                pb={[0, null, "50px"]}
                height={["63px", null, "auto"]}
                data-name="header"
                position="fixed"
                top="0"
                left="0"
            >
                <Flex
                    maxWidth={`${maxWidth - 40}px`}
                    width="100%"
                    flex="1 1 auto"
                    alignSelf="center"
                    justifyContent="flex-end"
                    px="20px"
                    lineHeight="1"
                    data-name="navigation-wrapper"
                >
                    <Box
                        display={["none", null, "flex"]}
                        justifyContent="flex-end"
                        position="relative"
                        width="100%"
                    >
                        <Navigation />
                    </Box>
                </Flex>
                <Flex
                    maxWidth={`${maxWidth - 40}px`}
                    width="100%"
                    pl="20px"
                    flex="1 0 auto"
                    alignItems="flex-end"
                    justifyContent="space-between"
                    pb={["2px", null, "5px"]}
                    height={["47px", null, "auto"]}
                    data-name="logo-wrapper"
                >
                    <Flex
                        flex="1 0 auto"
                        maxWidth={["145px", null, "227px"]}
                        height={["16px", null, "25px"]}
                    >
                        <Link style={LinkStyles} to="/">
                            <img src={Logo} alt="company logo" width="100%" />
                        </Link>
                    </Flex>
                    <Flex
                        display={["flex", null, "none"]}
                        alignItems="center"
                        lineHeight="1"
                        flex="0 1 auto"
                        justifyContent="flex-end"
                        position="relative"
                        top="4px"
                    >
                        <MobileNavigation />
                    </Flex>
                </Flex>
                <Flex width="100%" data-name="logo-red-text-wrapper">
                    <Flex
                        flex={["1 0 100%", null, "0 0 calc(50% - 18px) "]}
                        color="white"
                        alignItems="center"
                        justifyContent={["flex-start", null, "flex-end"]}
                        bg={colors.red}
                    >
                        <Flex
                            maxWidth={`${maxWidth / 2 - 20 - 18}px`}
                            width="100%"
                            pl="20px"
                            height={["16px", null, "22px"]}
                            fontSize={["12px", null, "15px"]}
                            fontWeight="bold"
                            lineHeight={["15px", null, "22px"]}
                        >
                            <span style={{ letterSpacing: ".15" }}>
                                ARCHITEKTEN - UND INGENIEURGESELLSCHAFT
                            </span>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
};

export default Header;
