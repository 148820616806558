import React from "react";
import styled from "styled-components";
import { typography, color, space, layout } from "styled-system";

const H1 = styled.h1`
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;
    ${color}
    ${typography};
    ${space};
`;

const H2 = styled.h2`
    font-weight: bold;
    ${(props) => !props.noUppercase && `text-transform: uppercase;`}
    line-height: 1;
    ${color}
    ${typography};
    ${space};
`;

export const H3 = styled.h3`
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    ${(props) => !props.noUppercase && `text-transform: uppercase;`}
    ${color}
    ${typography};
    ${space};
`;

const P = styled.p`
    font-weight: 300;
    ${color}
    ${typography};
    ${space};
    ${layout};
`;

const PDiv = styled.div`
    font-weight: 300;
    ${color}
    ${typography};
    ${space};
    ${layout};
`;

export const Title = ({ children, ...props }) => {
    return (
        <H1
            fontSize={["20px", "24px"]}
            mb={props.mb ? props.mb : ["20px", "29px"]}
        >
            {children}
        </H1>
    );
};

export const Headline = ({ children, ...props }) => {
    return (
        <H2
            fontSize={props.fontSize ? props.fontSize : ["20px", "24px"]}
            mb={props.mb ? props.mb : ["20px", "26px"]}
            fontWeight={
                props.headlineFontWeight ? props.headlineFontWeight : "bold"
            }
            noUppercase={props.noUppercase}
        >
            {children}
        </H2>
    );
};

export const Paragraph = ({ children, ...props }) => (
    <P
        fontSize={["15px", "15px"]}
        fontWeight={["400", "300"]}
        lineHeight={["20px", "22px"]}
        mb={props.mb}
        mt={props.mt}
        maxWidth={props.maxWidth}
    >
        {children}
    </P>
);

export const ParagraphDiv = ({ children, ...props }) => (
    <PDiv
        fontSize={["15px", "15px"]}
        fontWeight={["400", "300"]}
        lineHeight={["20px", "22px"]}
        mb={props.mb}
        mt={props.mt}
        maxWidth={props.maxWidth}
    >
        {children}
    </PDiv>
);
