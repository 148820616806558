import React from "react";
import { GlobalStyle } from "../css/GlobalStyle";

import { Flex } from "./FlexBox";
import { colors } from "../utilities/colors";

import Header from "./Header";
import Footer from "./Footer";
// desktop width = 1366px + 2 * 20px side padding
export const maxWidth = 1406;
export const sidePadding = 40;

const Layout = ({ children, ...props }) => {
    return (
        <Flex
            flexDirection="column"
            flex="1 0 100%"
            alignItems="center"
            mt={["calc(61px + 51px + 15px)", null, "calc(100px + 79px + 22px)"]}
        >
            <GlobalStyle />
            <Header />
            <Flex
                maxWidth={`${maxWidth - 40}px`}
                flexDirection="column"
                flex="1 0 auto"
                width="100%"
                px="20px"
                mt={props.contentTopMargin ? props.contentTopMargin : "-18px"}
            >
                <Flex
                    flex="1 0 100%"
                    width="100%"
                    flexDirection="column"
                    alignItems="center"
                >
                    {children}
                </Flex>
            </Flex>
            <Flex
                flex="0 0 auto"
                width="100%"
                justifyContent="center"
                bg={colors.red}
                mt={["calc(100px - 18px)", "calc(160px - 18px)"]}
            >
                <Flex
                    maxWidth={`${maxWidth - 40}px`}
                    width="100%"
                    color="white"
                    flexWrap="wrap"
                    px="20px"
                >
                    <Footer />
                </Flex>
            </Flex>
        </Flex>
    );
};

export default Layout;
