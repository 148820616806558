import { createGlobalStyle } from "styled-components";
import "@fontsource/source-sans-pro";
import "@fontsource/open-sans";

export const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex: 1 0 100%;
  }
  #___gatsby, #gatsby-focus-wrapper {
    display: flex;
    flex: 1 0 100%;
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Source Sans Pro";
    ${"" /* font-family: "Open Sans" */}
  }
`;
