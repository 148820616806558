import React from "react";
import { Link as _Link } from "gatsby";
import styled from "styled-components";
import { typography, space, position, layout } from "styled-system";

import { Flex, Box } from "./FlexBox";

const Link = styled(_Link)`
    text-decoration: none;
    color: white;
    font-weight: 600;
    &:hover {
        font-weight: bold;
    }
`;

const ExternalLink = styled.a`
    text-decoration: none;
    color: white;
    ${(props) =>
        props.hover &&
        `&:hover {
        font-weight: 600;
    }`}
`;

const Title = styled.span`
    transform: rotate(-90deg);
    transform-origin: 0 0;
    font-weight: 100;
    position: absolute;
    left: 0;
    letter-spacing: 1.6px;
    display: flex;
    align-items: center;
    ${typography};
    ${space};
    ${position};
    ${layout};
`;

const TextBox = styled.div`
    display: flex;
    flex-direction: column;
    ${space};
`;

const AddressLines = styled.span`
    display: block;
    font-weight: 300;
    color: white;
    ${typography};
    ${space};
    ${position};
`;

const Footer = () => {
    return (
        <>
            <Flex
                flex={["1 0 100%", "1 0 100%", "1 0 50%"]}
                minHeight="133px"
                my={["37px", null, "54px"]}
                mb={[0, null, "69px"]}
                position="relative"
            >
                <Title
                    fontSize={["14px", null, "17px"]}
                    letterSpacing="1px"
                    top={["56px", null, "77px"]}
                    left={["1px", null, "-1px"]}
                    lineHeight="0"
                    height={["10px", null, "13px"]}
                >
                    KONTAKT
                </Title>
                <TextBox
                    ml={["36px", null, "63px"]}
                    mt={["12px", null, "16px"]}
                >
                    <Flex
                        flexDirection="column"
                        fontSize={["13px", null, "16px"]}
                        lineHeight={["16px", null, "23px"]}
                    >
                        <AddressLines fontWeight="600">
                            IGKBERLINMBH
                        </AddressLines>
                        <AddressLines fontWeight="600">
                            Ingenieur- und Architektengesellschaft Kaiser Berlin
                            mbH
                        </AddressLines>
                        <Box
                            fontSize={["13px", null, "15px"]}
                            lineHeight={["18px", null, "normal"]}
                        >
                            <AddressLines>Holbeinstrasse 41</AddressLines>
                            <AddressLines>12203 Berlin</AddressLines>
                            <AddressLines>
                                <ExternalLink href="tel:+493089040400">
                                    T +49 30 890 40 400
                                </ExternalLink>
                            </AddressLines>
                            <AddressLines>F +49 30 890 40 427</AddressLines>
                            <AddressLines>
                                <ExternalLink
                                    hover
                                    href="mailto:info@igk-berlin.de"
                                >
                                    info@igk-berlin.de
                                </ExternalLink>
                            </AddressLines>
                        </Box>
                    </Flex>
                </TextBox>
            </Flex>
            <Flex
                flex={["1 0 100%", "1 0 100%", "1 0 50%"]}
                minHeight="133px"
                mt={["43px", null, "69px"]}
                mb={["70px"]}
                position="relative"
            >
                <Title
                    fontSize={["14px", null, "17px"]}
                    letterSpacing="1px"
                    left={["2px", null, "18px"]}
                    top={["103px", null, "120px"]}
                    lineHeight="0"
                    height={["10px", null, "13px"]}
                >
                    INFORMATIONEN
                </Title>
                <TextBox
                    ml={["36px", null, "83px"]}
                    mt={["12px", null, "-3px"]}
                >
                    <Flex
                        flexDirection="column"
                        style={{ textTransform: "uppercase" }}
                        fontWeight="bold"
                        fontSize={["13px", null, "16px"]}
                        letterSpacing="0.25px"
                        lineHeight={["24px", null, "30px"]}
                    >
                        <ul style={{ listStyle: "none" }}>
                            <li>
                                <Link to="/kontakt">Kontakt</Link>
                            </li>
                            <li>
                                <Link to="/kontakt">Impressum</Link>
                            </li>
                            <li>
                                <Link to="/datenschutz">Datenschutz</Link>
                            </li>
                        </ul>
                        <AddressLines
                            style={{ textTransform: "uppercase" }}
                            fontSize={["10px", null, "12px"]}
                            letterSpacing="0.25px"
                            position="relative"
                            top={["17px", null, "12px"]}
                        >
                            © {new Date().getFullYear()} IGKBERLINMBH &nbsp;|
                            &nbsp; Design:{" "}
                            <ExternalLink hover href="mailto:sandra@pieroni.de">
                                Sandra Pieroni
                            </ExternalLink>
                        </AddressLines>
                    </Flex>
                </TextBox>
            </Flex>
        </>
    );
};

export default Footer;
