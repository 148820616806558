import React from "react";
import { Link as _Link } from "gatsby";
import { layout, flexbox, color, space, typography } from "styled-system";
import styled from "styled-components";
import { Drawer, Button as _Button } from "@material-ui/core";
import Burger from "../images/hamburger.svg";
import BurgerNonActive from "../images/hamburger-none-active.svg";
import { colors } from "../utilities/colors";
import { Flex } from "./FlexBox";

const Button = styled(_Button)`
    -webkit-tap-highlight-color: transparent;
    &:hover {
        background: transparent;
    }
`;

const Nav = styled.nav`
    ${layout};
    ${flexbox};
    ${color};
    ${space};
    ${typography};
`;

const Link = styled(_Link)`
    text-decoration: none;
    text-transform: uppercase;
    color: #000;
    font-weight: 600;
    font-size: 16px;
    margin-left: 26px;
    border-top: 0.5px solid white;
    &:last-of-type {
        border-bottom: 0.5px solid white;
    }
    ${space};
    &:hover {
        color: white;
    }
    @media screen and (min-width: 40em) {
        &:hover {
            color: ${colors.red};
        }
    }
`;

const MobileLink = styled(Link)`
    color: white;
    margin-left: 0;
    padding-left: 20px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: 500;
`;

const Navigation = () => {
    return (
        <Nav display="inline" flexDirection="row">
            <Link activeStyle={{ color: colors.red }} to="/projekte" ml="0">
                Projekte
            </Link>
            <Link activeStyle={{ color: colors.red }} to="/leistungen">
                Leistungen
            </Link>
            <Link activeStyle={{ color: colors.red }} to="/profil">
                Profil
            </Link>
            <Link activeStyle={{ color: colors.red }} to="/kontakt">
                Kontakt
            </Link>
        </Nav>
    );
};

export const MobileNavigation = () => {
    const [state, setState] = React.useState(false);

    const toggleDrawer = (open) => (event) => {
        setState(open);
    };

    const list = () => (
        <Flex onClick={toggleDrawer(false)} data-name="drawer">
            <Nav
                display="flex"
                flexDirection="column"
                bg={colors.red}
                pt="111px"
                pb="60px"
                width="100%"
            >
                <MobileLink
                    activeStyle={{
                        color: "#000",
                        backgroundColor: "white",
                    }}
                    to="/projekte"
                >
                    Projekte
                </MobileLink>
                <MobileLink
                    activeStyle={{
                        color: "#000",
                        backgroundColor: "white",
                    }}
                    to="/leistungen"
                >
                    Leistungen
                </MobileLink>
                <MobileLink
                    activeStyle={{
                        color: "#000",
                        backgroundColor: "white",
                    }}
                    to="/profil"
                >
                    Profil
                </MobileLink>
                <MobileLink
                    activeStyle={{
                        color: "#000",
                        backgroundColor: "white",
                    }}
                    to="/kontakt"
                >
                    Kontakt
                </MobileLink>
                <MobileLink
                    activeStyle={{
                        color: "#000",
                        backgroundColor: "white",
                    }}
                    to="/kontakt"
                >
                    Impressum
                </MobileLink>
                <MobileLink
                    activeStyle={{
                        color: "#000",
                        backgroundColor: "white",
                    }}
                    to="/datenschutz"
                >
                    Datenschutz
                </MobileLink>
            </Nav>
        </Flex>
    );
    return (
        <>
            <Button onClick={toggleDrawer(!state ? true : false)}>
                {!state ? (
                    <img
                        src={Burger}
                        style={{ width: "100%", maxWidth: "25px" }}
                        alt="navigation-active"
                    />
                ) : (
                    <img
                        src={BurgerNonActive}
                        style={{ width: "100%", maxWidth: "25px" }}
                        alt="navigation-none-active"
                    />
                )}
            </Button>
            <Drawer anchor={"top"} open={state} onClose={toggleDrawer(false)}>
                {list()}
            </Drawer>
        </>
    );
};

export default Navigation;
