import styled from "styled-components";
import {
	layout,
	flexbox,
	color,
	position,
	space,
	typography,
	border,
} from "styled-system";
// https://styled-system.com/api
// The layout utility includes style props for width, height, display, minWidth, minHeight,
// maxWidth, maxHeight, size, verticalAlign, overflow, overflowX, and overflowY.

// The flexbox utility includes style props for alignItems, alignContent, justifyItems, justifyContent,
// flexWrap, flexDirection, flex, flexGrow, flexShrink, flexBasis, justifySelf, alignSelf, and order.

export const Flex = styled.div`
	display: flex;
	${layout};
	${flexbox};
	${color};
	${position};
	${space};
	${typography};
	${border};
`;

export const Box = styled.div`
	${layout};
	${flexbox};
	${color};
	${position};
	${space};
	${typography};
	${border};
`;
